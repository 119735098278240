export default class StrapiClient {
    constructor(client, locale) {
        this.client = client;
        this.locale = locale;
    }

    find(elementKey, elementId, populate = '*') {
        const params = {
            locale: this.locale,
            'populate': populate,
        };

        return new Promise((resolve, reject) => {
            var self = this;
            self.client.get(
                elementKey + '/' + elementId,
                {'params': params}
            )
                .then(function (response) {
                    const returno = {
                        data: response.data.data,
                        meta: response.data.meta
                    };
                    resolve(returno);
                })
                .catch(function (response) {
                    reject(response);
                });
        });
    }

    async findAll(elementKey, conditions = {}, populate = '*', page = 1, limit= 25) {
        return this.findBy(elementKey, conditions, populate, page, limit);
    }

    async findBy(elementKey, conditions = {}, populate = '*', page = 1, limit= 25) {

        const params = {
            locale: this.locale,
            'populate': populate,
            'pagination[page]': page,
            'pagination[pageSize]': limit,
            'sort[0]': 'publishedAt:desc',
        };

        if (conditions) {
            for (const [key, value] of Object.entries(conditions)) {
                params['filters' + key] = value
            }
        }

        return new Promise((resolve, reject) => {
            var self = this;
            self.client.get(
                elementKey,
                {'params': params}
            )
            .then(function (response) {
                const returno = {
                  data: response.data.data,
                  meta: response.data.meta
                };
                resolve(returno);
            })
            .catch(function (response) {
                reject(response);
            });
        });
    }
}
