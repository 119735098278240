import { getManagerClient } from '@/helpers/clients/manager-client'

export const state = {
    currentUser: window.sessionStorage.getItem('authUser'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('authUser', newValue)
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    getUser(state) {
        return state.currentUser
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { username, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getManagerClient().login(username, password).then((response) => {
            const user = response.data;
            commit('SET_CURRENT_USER', user);
            return user
        });
    },

    // Logs out the current user.
    // eslint-disable-next-line no-unused-vars
    logOut({ commit }) {
        commit('SET_CURRENT_USER', null)
        window.sessionStorage.clear();
        return new Promise((resolve) => {
           resolve(true);
        });
    },

    // register the user
    register({ commit, dispatch, getters }, { username, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getManagerClient().registerUser(username, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { username } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getManagerClient().forgetPassword(username).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)

        return getManagerClient().refresh().then((user) => {
            commit('SET_CURRENT_USER', user.data)
            return user;
        }).catch(() => {
            commit('SET_CURRENT_USER', null)
            return null;
        });
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
