class ManagerClient {
    constructor(client, locale) {
        this.client = client;
        this.locale = locale;
    }

    async login(username, password) {
        var self = this;

        return new Promise((resolve, reject) => {
            self.client.post('login',
                {'username': username, 'password': password}
                )
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (response) {
                    reject(self._handleError(response));
                });
        });
    }

    getAuthenticatedUser = () => {
        if (!window.sessionStorage.getItem('authUser'))
            return null;
        return JSON.parse(window.sessionStorage.getItem('authUser'));
    }

    async refresh() {
        var self = this;

        return new Promise((resolve, reject) => {
            self.client.get('refresh', {
                headers: {
                    Authorization: `Bearer ${self.getUserToken()}`
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (response) {
                    reject(self._handleError(response));
                });
        });
    }

    async logout() {
        var self = this;

        return new Promise((resolve, reject) => {
            self.client.get('logout', {
                headers: {
                    Authorization: `Bearer ${self.getUserToken()}`
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (response) {
                    reject(self._handleError(response));
                });
        });
    }

    async getCharacters() {
        var self = this;

        return new Promise((resolve, reject) => {
            self.client.get('characters', {
                headers: {
                    Authorization: `Bearer ${self.getUserToken()}`
                }
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (response) {
                    reject(self._handleError(response));
                });
        });
    }
    async getCharacter(characterId) {
        var self = this;

        return new Promise((resolve, reject) => {
            self.client.get('characters/view/' + characterId, {
                headers: {
                    Authorization: `Bearer ${self.getUserToken()}`
                }
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (response) {
                    reject(self._handleError(response));
                });
        });
    }

    async getShopCharacters(itemId) {
        var self = this;

        return new Promise((resolve, reject) => {
            self.client.get('shop/characters/' + itemId + '/', {
                headers: {
                    Authorization: `Bearer ${self.getUserToken()}`
                }
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (response) {
                    reject(self._handleError(response));
                });
        });
    }

    getUserToken = () => {
        const user = this.getAuthenticatedUser();
        if (user) {
            return user.token;
        }

        return '';
    }

    _handleError(error) {
        if (error.data && error.data.message)
            return error.data.message;

        return "Something went wrong";
    }
}

let _managerClient = null;

const initManagerClient = (axios, locale) => {
    if (!_managerClient) {
        _managerClient = new ManagerClient(axios, locale);
    }
    return _managerClient;
}

/**
 * Returns the firebase backend
 */
const getManagerClient = () => {
    return _managerClient;
}

export { initManagerClient, getManagerClient };
