import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';

import Maska from 'maska'
import i18n from "./i18n"

import BootstrapVueNext from 'bootstrap-vue-next'

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import "../src/design/app-dark.scss";

import Axios from 'axios'

import StrapiClient from './helpers/clients/strapi-client'
import {initManagerClient} from './helpers/clients/manager-client'

const { setupCache } = require('axios-cache-interceptor');

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_APIKEY,
//   authDomain: process.env.VUE_APP_AUTHDOMAIN,
//   databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//   projectId: process.env.VUE_APP_PROJECTId,
//   storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
//   appId: process.env.VUE_APP_APPId,
//   measurementId: process.env.VUE_APP_MEASUREMENTID
// };

const application = createApp (App)

application.config.globalProperties.$http = Axios;
application.config.globalProperties.$http.defaults.withCredentials = true;

const managerHttp = Axios.create({
  baseURL: 'https://api.3server.net/api/',
  withCredentials: false
});
const strapiHttp = setupCache(Axios.create({
  baseURL: 'https://cdn.3server.net/api/',
  withCredentials: false
}));

application.config.globalProperties.$managerClient = initManagerClient(managerHttp, 'en');
application.config.globalProperties.$strapiClient = new StrapiClient(strapiHttp, 'en');

//initManagerBackend(Vue.prototype.$managerClient);


import store from "./state/store";

application
    .use(store)
    .use(router)
    .use(require('vue-chartist'))
    .use(BootstrapVueNext)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(i18n)
    .use(registerScrollSpy)
    .use(Maska)
    .mount("#app")
